import React, { Fragment, Component } from 'react';
import _ from 'lodash';
import { Link, graphql } from 'gatsby';
import { colors, breakpoints, fonts } from '../style-utilities/variables';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';

//import components
import NavBar from '../components/NavBar';
import ContactBanner from '../components/ContactBanner';
import Related3Grid from '../components/Related/Related3Grid';
import StyledSectionComponent from '../components/StyledSectionComponent';
import SEO from '../components/SEO';
import DefaultSharingImage from './../static/ansira-logo-sharing.png';
import GlobalOurClients from '../components/GlobalOurClients';
import { faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons';

const SubServicePage = styled.main`
  margin: 0.75em auto;
  margin-top: 0;
  .page-container {
    max-width: 1500px;
    margin: 0 auto;
  }
  h1 {
    font-size: 48px;
  }
.info-holder {
  margin-bottom: 5rem;
}
  .intro-copy {
    font-size: 22px;
  }
  .intro {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
    @media (min-width: ${breakpoints.tablet}) {
      width: 80%;
      margin: auto;
      margin-bottom: 2rem;
    }
  }
  .feature-cards-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 0;
    @media (min-width: ${breakpoints.tabletMax}) {
      width: 75%;
    }
    .feature-cards {
      @media (min-width: ${breakpoints.tablet}) {
        width: 47%;
      }
      .feature-headline {
        font-size: 26px;
      }
      .feature-card {
        margin-bottom: 2rem;
        margin-left: 0;
        margin-right: 1rem;
        .feature-list {
          list-style: none;
          color: #757575;
          margin-left: 2.45rem;
        }
        .feature-list-title {
          font-weight: bold;
          font-size: 16px;
          color: ${colors.black};
          margin-bottom: 15px;
        }
        .feature-content {
          color: #757575;
        }
      }
    }
  }

  .by-the-numbers {
    text-align: center;
    .numbers-headline {
      margin-bottom: 60px;
    }
    .numbers-items-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .numbers-items {
        width: 50%;
        margin-bottom: 60px;
        @media (min-width: ${breakpoints.tabletMax}) {
          width: 23%;
        }
        .numbers-number {
          color: #dddddd;
          font-size: 32px;
          font-weight: bold;
          line-height: 1.15em;
          max-width: 220px;
          margin: 0 auto 15px;
          @media (min-width: ${breakpoints.mobileMax}) {
            font-size: 50px;
          }
        }
        .numbers-item {
          color: ${colors.black};
          font-size: 22px;
          font-weight: bold;
          line-height: 1.25em;
          max-width: 220px;
          margin: 0 auto;
        }
      }
    }
  }
`;

class SubServicePageTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewPayload: null,
      isPreviewMode: false
    };
  }

  render() {
    let post = this.props.data.wordpressWpCapabilitiesPost,
      topics = this.props.data.allWordpressWpTopic.edges,
      tpPost = this.props.data.wordpressWpTopic,
      blogPosts = this.props.data.allWordpressPost.edges,
      caseStudies = this.props.data.allWordpressWpCaseStudy.edges,
      whitePapers = this.props.data.allWordpressWpWhitePaper.edges,
      arrayBlogs = [],
      arrayNews = [],
      arrayCases = [],
      arrayPapers = [],
      filteredServices = [],
      filteredTopics = [];

    //hold mixed item content
    const arrayMixed = [];
    let arrayAll = [];

    //Sort Posts by date
    blogPosts = blogPosts.sort(function(a, b) {
      return new Date(b.node.date) - new Date(a.node.date);
    });

    caseStudies = caseStudies.sort(function(a, b) {
      return new Date(b.node.date) - new Date(a.node.date);
    });

    whitePapers = whitePapers.sort(function(a, b) {
      return new Date(b.node.date) - new Date(a.node.date);
    });

    // Find any BLOGPOSTS with this capability as a topic
    blogPosts.forEach(item => {
      item.node.topic.forEach(tp => {
        if (tp === tpPost.wordpress_id) {
          arrayBlogs.push(item.node);
        }
      });
    });
    // If no Blog Posts have this capability as a topic, fetch latest Blog Post
    if (arrayBlogs.length === 0) {
      arrayBlogs.push(blogPosts[0].node);
    }

    // Find any CASESTUDIES with this capability as a topic
    caseStudies.forEach(item => {
      item.node.topic.forEach(tp => {
        if (tp === tpPost.wordpress_id) {
          arrayCases.push(item.node);
        }
      });
    });
    // If no Case Study has capability as a topic, fetch latest Case Study
    if (arrayCases.length === 0) {
      arrayCases.push(caseStudies[0].node);
    }

    // Find any WHITEPAPERS with this capability as a topic
    whitePapers.forEach(item => {
      item.node.topic.forEach(tp => {
        if (tp === tpPost.wordpress_id) {
          arrayPapers.push(item.node);
        }
      });
    });
    // If no White Paper has capability as a topic, fetch latest White Paper
    if (arrayPapers.length === 0) {
      arrayPapers.push(whitePapers[0].node);
    }

    //filter out dummy posts
    const filterArrays = arr => {
      return arr.filter(el => el.slug !== 'do-not-delete');
    };

    const filteredBlogs = filterArrays(arrayBlogs);
    const filteredCases = filterArrays(arrayCases);
    const filteredPapers = filterArrays(arrayPapers);


    //Push related content into a single array and sort by date
    //=========================================================
    if (filteredBlogs) {
      filteredBlogs.forEach(function(o) {
        arrayAll.push(o);
      });
    }
    if (filteredCases) {
      filteredCases.forEach(function(o) {
        arrayAll.push(o);
      });
    }
    if (filteredPapers) {
      filteredPapers.forEach(function(o) {
        arrayAll.push(o);
      });
    }




    //function to remove duplicates that are created if post has same topic,service or vertical
    function removeDuplicates(arr) {
      const final = [];
      arr.map((e, i) => !final.includes(e) && final.push(e));
      return final;
    }

    arrayAll = removeDuplicates(arrayAll);

    arrayMixed.push(arrayAll[0]); //skim the first 3 related articles to display
    arrayMixed.push(arrayAll[1]);
    arrayMixed.push(arrayAll[2]);


    return (
      <SubServicePage className="animate">
        <SEO
          isBlogPost={false}
          postData={post}
          postImage={DefaultSharingImage}
        />
        <NavBar name={'Capabilities'} link={'capabilities'} />

        <div className="page-container">
          <h1 className="info-holder">{ReactHtmlParser(post.title)}</h1>
          <p className="intro-copy copy">
            {ReactHtmlParser(post.acf.overview_copy)}
          </p>
        </div>

        <div className="page-container">
          <div className="feature-cards-container">
            <div className="feature-cards">
              {post.acf.feature_cards.map(item => {
                return (
                  <div className="feature-card">
                    <h2 className="feature-headline">
                      {item.feature_headline}
                    </h2>
                    <div className="feature-content">
                      {ReactHtmlParser(item.feature_content)}
                    </div>
                    {/* {item.feature_list_title ? (
                      <p className="feature-list-title">
                        {item.feature_list_title}
                      </p>
                    ) : (
                      ''
                    )}
                    {item.feature_list ? (
                      <ul className="feature-list">
                        {item.feature_list.map(item => {
                          return <li>{item.list_item}</li>;
                        })}
                      </ul>
                    ) : (
                      ''
                    )} */}
                  </div>
                );
              })}
            </div>
            <div className="feature-cards feature-cards-right">
              {post.acf.feature_cards_right.map(item => {
                return (
                  <div className="feature-card">
                    <h2 className="feature-headline">
                      {item.feature_headline}
                    </h2>
                    <div className="feature-content">
                      {ReactHtmlParser(item.feature_content)}
                    </div>
                    {/* {item.feature_list_title ? (
                      <p className="feature-list-title">
                        {item.feature_list_title}
                      </p>
                    ) : (
                      ''
                    )}
                    {item.feature_list ? (
                      <ul className="feature-list">
                        {item.feature_list.map(item => {
                          return <li>{item.list_item}</li>;
                        })}
                      </ul>
                    ) : (
                      ''
                    )} */}
                  </div>
                );
              })}
            </div>
          </div>
          {post.acf.numbers_headline !== '' || post.acf.numbers_items !== '' ? (
            <div className="by-the-numbers">
              <h2 className="numbers-headline">{post.acf.numbers_headline}</h2>
              <div className="numbers-items-wrapper">
                {post.acf.numbers_items.map(item => {
                  return (
                    <div className="numbers-items">
                      <p className="numbers-number">{item.numbers_number}</p>
                      <p className="numbers-item">{item.numbers_item}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <></>
          )}

          <GlobalOurClients
            headline={post.acf.client_logo_headline}
            logos={post.acf.client_logos}
          />

          <Related3Grid header="Read These Next" items={arrayMixed} />
        </div>
        <ContactBanner
          headline={post.acf.contact_headline}
          cta={post.acf.contact_cta}
          url={post.acf.contact_url}
        />
      </SubServicePage>
    );
  }
}

export const query = graphql`
  query($slug: String!) {
    wordpressWpTopic(slug: { eq: $slug }) {
      wordpress_id
      slug
    }
    allWordpressWpTopic {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    allWordpressWpWhitePaper {
      edges {
        node {
          wordpress_id
          type
          date(formatString: "MMMM D, YYYY")
          title
          slug
          topic
          better_featured_image {
            source_url
            alt_text
          }
          acf {
            excerpt
          }
        }
      }
    }
    allWordpressWpNews {
      edges {
        node {
          wordpress_id
          type
          date(formatString: "MMMM D, YYYY")
          title
          slug
          topic
          acf {
            excerpt
          }
        }
      }
    }
    allWordpressPost {
      edges {
        node {
          wordpress_id
          content
          type
          date(formatString: "MMMM D, YYYY")
          title
          slug
          author {
            name
            slug
          }
          better_featured_image {
            source_url
            alt_text
          }
          acf {
            excerpt
          }
          topic
        }
      }
    }
    allWordpressWpCaseStudy {
      edges {
        node {
          wordpress_id
          type
          title
          slug
          topic
          date(formatString: "MMMM D, YYYY")
          better_featured_image {
            alt_text
            source_url
          }
          acf {
            client
            client_logo {
              source_url
            }
            quote_copy
            quote_author
            excerpt
          }
        }
      }
    }
    wordpressWpCapabilitiesPost(slug: { eq: $slug }) {
      title
      type
      content
      slug
      acf {
        seo_title
        seo_canonical
        seo_description
        overview_copy
        feature_cards {
          feature_content
          feature_headline
        }
        feature_cards_right {
          feature_content
          feature_headline
        }
        numbers_headline
        numbers_items {
          numbers_item
          numbers_number
        }
        client_logo_headline
        client_logos {
          source_url
        }
        contact_headline
        contact_cta
        contact_url
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
  }
`;

export default SubServicePageTemplate;
